import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionGroup } from '@mui/joy';

export function Accordeon() {
  return (
    <div className='faqBox'>
        <AccordionGroup sx={{ borderLeft: 0, borderRight: 0, fontFamily: '"Inter", sans-serif' }}>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                How can we work together?
                </AccordionSummary>
                <AccordionDetails className='faqResponse'>
                For starters we will talk via email and video call to find out how our agency can help your businness thrive.
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                >
                Can I request only design-related services?
                </AccordionSummary>
                <AccordionDetails className='faqResponse'>
                Sure! We are here to help you however you need. We can help you with design services or any other service that we offer such 
                as web development, SEO, digital marketing consulting, support competitors analysis, high-fidelity mockups and more. 
                Send us a message about your needs.
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                >
                Which website builder should I choose?
                </AccordionSummary>
                <AccordionDetails className='faqResponse'>
                We give you 3 options: Custom-coded websites, Squarespace, or WIX. By understanding your requirements, timeline, budget, and preferences, 
                we'll seamlessly guide you towards selecting the optimal website template, hosting, and services for your business.
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                >
                    What if I don't like the design?
                </AccordionSummary>
                <AccordionDetails className='faqResponse'>
                    No worries! We'll continue to revise the design until you're satisfied.
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                >
                Will you mantain my website after launch?
                </AccordionSummary>
                <AccordionDetails className='faqResponse'>
                We offer support and update service, you can let us know if you need this service.
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                >
                How long it takes to complete the services?
                </AccordionSummary>
                <AccordionDetails className='faqResponse'>
                It depends on your request, but we will give you an estimate timeline and a launch date during our meetings.
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                >
                Do you offer print services?
                </AccordionSummary>
                <AccordionDetails className='faqResponse'>
                No, we don't. We're essentially a web design firm. Our agency specializes on website design, webdevelopment, digital marketing and other digital services.
                </AccordionDetails>
            </Accordion>
        </AccordionGroup>
    </div>
  );
}
