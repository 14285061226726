import '../App.css';
import Logo from '../Assets/Logo-White.png';
import React, { useEffect, useState } from "react";

function Navbar() {

  const page = useState('https://www.085digital.com');

  useEffect(() => {

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const animatedTop = entry.target.querySelector('.animated-top');
        const navAnimatedTop = entry.target.querySelector('.nav-list');
    
        if (entry.isIntersecting) {
          animatedTop.classList.add('fixed-top');
          navAnimatedTop.classList.add('fixed-top');
        return; // if we added the class, exit the function
        }
    
        // We're not intersecting, so remove the class!
        animatedTop.classList.remove('fixed-top');
        navAnimatedTop.classList.remove('fixed-top');
      });
    });
    
    observer.observe(document.querySelector('.Nav')); 

  }, [page])

  return (
    <div className="Nav" id='Home'>
        <div className='animated-top'>
          <a href='/'>
            <img alt='085 Digital Logo' className='primary-logo' src={Logo}/>
          </a>
        </div>
        <nav>
            <ul className='nav-list'>
                <li className='list-item'><a href='/'>Home</a></li>
                <li className='list-item'><a href='#Services'>Services</a></li>
                <li className='list-item'><a href='#About'>About</a></li>
                <a href='#Contact'><button>Request a quote</button></a>
            </ul>
        </nav>
    </div>
  );
}

export default Navbar;
