import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export const ContactForm = () => {
    const form = useRef();
    const SERVICE_EMAIL = 'service_oj0ypeh';
    const TEMPLATE_EMAIL = 'template_1h6ksfm';
    const PUBLIC_KEY = 'KV_VG6XtpTJV7AkhN';

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
        .sendForm(SERVICE_EMAIL, TEMPLATE_EMAIL, form.current, {
            publicKey: PUBLIC_KEY,
        })
        .then(
            () => {
                alert("Your message was sent. Thank you for contacting us.");
                e.target.reset();
            },
            (error) => {
                alert("Something went wrong. Please try again or send an email to contact@085digital.com");
                console.log('FAILED...', error.text);
            },
        );
    };

  return (
    <div className='contact-wrapper'>
        <form id="contactForm" ref={form} onSubmit={sendEmail}>
            <textarea id="message" name="message" placeholder='Tell us about it...' required/>
            <input id="email" type="email" name="user_email" placeholder='Email address' required/>
            <input id="fname" type="text" name="user_name" placeholder='Name' required/>
            <input id="submitBtn" type="submit" value="Submit" />
        </form>
    </div>
  );
};