import '../../App.css';
import React, { useRef, useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { useDrag } from '@use-gesture/react'
import { IMAGES } from '../utils/Images.js';

const ImageCarousel = () => {
    const [{ x }, set] = useSpring(() => ({
      x: 0,
      config: {
        tension: 369,
        friction: 60
      }
    }))
  
    const containerRef = useRef(null)
    const imagesRef = useRef(null)
  
    const [containerWidth, setContainerWidth] = useState(0)
    const [imagesWidth, setImagesWidth] = useState(0)
  
    const TOTAL_PADDING = 20 * IMAGES.length
  
    useEffect(() => {
      const measureContainerWidth = () => {
        setContainerWidth(containerRef.current.clientWidth)
      }
  
      const measureImagesWidth = () => {
        const imagesNode = imagesRef.current.childNodes
        const imagesArr = Array.from(imagesNode)
        const imagesSumWidth = imagesArr.reduce(
          (acc, node) => acc + node.clientWidth,
          0
        )
        setImagesWidth(imagesSumWidth)
      }
  
      measureContainerWidth()
      measureImagesWidth()
  
      window.addEventListener("resize", measureContainerWidth)
      window.addEventListener("resize", measureImagesWidth)
  
      return () => {
        window.removeEventListener("resize", measureContainerWidth)
        window.removeEventListener("resize", measureImagesWidth)
      }
    }, [containerWidth])
  
    const bind = useDrag(
      state => {
        const { offset } = state
        const xOffset = offset[0]
  
        set({ x: xOffset })
      },
      {
        axis: "x",
        bounds: {
          left: -(imagesWidth - containerWidth + TOTAL_PADDING),
          right: 0
        },
        rubberband: true
      }
    )
  
    return (
      <main ref={containerRef} {...bind()} className="container-carousel">
        <animated.div
          ref={imagesRef}
          style={{ transform: x.to(x => `translate3d(${x}px, 0,0)`) }}
          className="images"
        >
          {IMAGES.map(image => (
            <img key={image.id} src={image.url} alt="Previous works" />
          ))}
        </animated.div>
      </main>
    )
  }

export default ImageCarousel;
