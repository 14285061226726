export const IMAGES = [
    {
      id: 0,
      url:
        "https://085digital.com/storage-of-past-work-images/BritoWebsite.png"
    },
    {
      id: 1,
      url:
        "https://085digital.com/storage-of-past-work-images/SustainableLifeMockup.png"
    },
    {
      id: 2,
      url:
        "https://085digital.com/storage-of-past-work-images/Anooka-Mockups.png"
    },
    {
      id: 3,
      url:
        "https://085digital.com/storage-of-past-work-images/Lasha-Mockups.png"
    },
    {
      id: 4,
      url:
        "https://085digital.com/storage-of-past-work-images/Envol-Mockup1.png"
    },
    {
      id: 5,
      url:
        "https://085digital.com/storage-of-past-work-images/MaxDesignWork.png"
    },
  ];
  