import '../App.css';
import React, { useEffect, useState } from "react";
import MirelaHolanda from '../Assets/Mirela-Holanda-profile.jpeg';
import AlineVeloso from '../Assets/Aline-Veloso-Profile.jpg'

function About() {
  const page = useState('https://www.085digital.com');

  useEffect(() => {

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const animatedTop = entry.target.querySelector('.animated-top');
        const animatedBottom = entry.target.querySelector('.animated-bottom');
        
        if (entry.isIntersecting) {
          animatedTop.classList.add('fixed-top');
          animatedBottom.classList.add('fixed-bottom');
        return; // if we added the class, exit the function
        }
    
        // We're not intersecting, so remove the class!
        animatedTop.classList.remove('fixed-top');
        animatedBottom.classList.remove('fixed-bottom');
      });
    });
    
    observer.observe(document.querySelector('#About')); 

  }, [page])
  
  return (
    <div className="container" id='About'>
        <div className='animated-top'>
          <h2>About</h2>
        </div>
        <div className='animated-bottom' id='aboutSubtitle'>
            <p>
            085 Digital is a small web design agency that specializes in the design and development of landpages and sites 
            for other small businesses. At 085 Digital, we value creativity, partnership and inovation. We're a familly 
            business that trives on creating solutions for entrepreneurs to achive their goals online. Come and talk to us 
            about your business needs. We will help create and develop your business digital presence and online strategy.
            </p>
        </div>
        <div className='about-cards'>
          <div className="two-columns-grid aboutContentBox">
            <div>
              <img src={MirelaHolanda} alt='Mirela Holanda profile'/>
            </div>
            <div className='about-text'>
              <h3>Mirela Holanda (she/her)</h3>
              <h4>Development/SEO</h4>
              <p>
              Driven by a passion for technology and innovation, Mirela is a software developer with hands-on experience crafting web solutions and sites. 
              Together with her coding skills, she boasts a decade-long proficiency in digital marketing, specializing in online strategy and SEO-centric content optimization. 
              She greatly enjoy combining her tech enthusiasm with her keen eye for business ideas and strategic thinking, making her an invaluable asset in any project. 
              </p>
            </div>
          </div>

          <div className="two-columns-grid aboutContentBox">
            <div>
              <img src={AlineVeloso} alt='Aline Veloso profile'/>
            </div>
            <div className='about-text'>
              <h3>Aline Veloso (she/her)</h3>
              <h4>Product/Visual Design</h4>
              <p>
              A creative and detail-oriented Product Designer with experience delivering end-to-end mobile, web, and marketing products. 
              Aline is passionate about user-centered experiences and learning new ways to combine function and beauty in innovative design products. 
              </p>
            </div>
          </div>
        </div>
    </div>
  );
}

export default About;
