import '../App.css';
import React, { useEffect, useState } from "react";

function OurProcess() {
  const page = useState('https://www.085digital.com');

  useEffect(() => {

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const animatedTop = entry.target.querySelector('.animated-top');
        const animatedBottom = entry.target.querySelector('.animated-bottom');
        
        if (entry.isIntersecting) {
          animatedTop.classList.add('fixed-top');
          animatedBottom.classList.add('fixed-bottom');
        return; // if we added the class, exit the function
        }
    
        // We're not intersecting, so remove the class!
        animatedTop.classList.remove('fixed-top');
        animatedBottom.classList.remove('fixed-bottom');
      });
    });
    
    observer.observe(document.querySelector('#Process')); 

  }, [page])
  
  return (
    <div className="container" id='Process'>
        <div className='animated-top'>
          <h2>Our process</h2>
        </div>
        <div className='animated-bottom' id='processCardsContainer'>
            <div className='process-card'>
              <div className="two-columns-grid">
                  <div className='block'>
                    <h3>Onboarding</h3>
                  </div>
                  <div className='block'>
                    <p>
                    We will begin the process with an introductory call to get to know you and your brand. This will help us determine 
                    if we are a good fit for the project you have in mind and what solutions we can offer to your business. 
                    </p>
                  </div>
              </div>
            </div>
            <div className='process-card'>
              <div className="two-columns-grid">
                  <div className='block'>
                    <h3>Strategy</h3>
                  </div>
                  <div className='block'>
                    <p>
                    The strategy phase is the foundation to start building your project. 
                    This is where we will identify what sets your brand apart and how we can effectively communicate it to your target audience. 
                    </p>
                  </div>
              </div>
            </div>
            <div className='process-card'>
              <div className="two-columns-grid">
                  <div className='block'>
                    <h3>Design and Development</h3>
                  </div>
                  <div className='block'>
                    <p>
                    The design and development phase will begin once we have a clear understanding of your brand and target audience. 
                    We will work on developing and designing a website or brand that reflects your core values, ensuring that you can confidently market to your ideal customers. 
                    </p>
                  </div>
              </div>
            </div>
            <div className='process-card'>
              <div className="two-columns-grid">
                  <div className='block'>
                    <h3>Delivery</h3>
                  </div>
                  <div className='block'>
                    <p>
                    Finally, once we have your approval, all you need to do is launch it for the world to see! We will provide you a document with the tools, access and other orientations 
                    you may need to refere to in the future. If you decide to keep working together for support and maintenance, we can take care of that as well, let us know!
                    </p>
                  </div>
              </div>
            </div>
        </div>
    </div>
  );
}

export default OurProcess;
