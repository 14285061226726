import '../App.css';
import Logo from '../Assets/Logo.png';
import Instagram from "../Assets/instagram.png";

function Footer() {
  return (
    <div className="Footer section-wrapper">
        <div className='footer-first-block'>
          <div className='footer-first-cl'>
            <img alt='085 Digital Logo' className='logo' src={Logo}/>
          </div>
          <div className='footer-second-cl'>
            <a href="https://www.instagram.com/085digitalagency/">
              <img src={Instagram} alt="Follow us on Instagram at instagram.com/085digitalagency"/>
            </a>
            <p>
              contact@085digital.com
            </p>
            <p>
              Proudly headquartered in Ottawa, ON
            </p>
          </div>
        </div>
          <nav>
            <ul className='nav-list'>
                <li className='list-item'><a href='/'>Home</a></li>
                <li className='list-item'><a href='#Services'>Services</a></li>
            </ul>
            <ul className='nav-list'>
                <li className='list-item'><a href='#About'>About</a></li>
                <li className='list-item'><a href='#Contact'>Contact</a></li>
            </ul>
          </nav>
    </div>
  );
}

export default Footer;
