import '../App.css';
import React, { useEffect, useState } from "react";

function Home() {  

  const page = useState('https://www.085digital.com');

  useEffect(() => {

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const animatedBottom = entry.target.querySelector('.animated-bottom');
        
        if (entry.isIntersecting) {
          animatedBottom.classList.add('fixed-bottom');
        return; // if we added the class, exit the function
        }
    
        // We're not intersecting, so remove the class!
        animatedBottom.classList.remove('fixed-bottom');
      });
    });
    
    observer.observe(document.querySelector('.Home-container')); 

  }, [page])

  return ( 
    
      <div className='Home-container'>
        <div className='Header-Image'>
          <header className="App-header animated-bottom">
            <h1>Web design agency<br/>for small business</h1>
            <p>
                Website creation, planning, online strategy and other digital solutions.<br/>We can make your business be seen online.
            </p>
            <a href='#Contact' id='contactBtn'><button>Let's work together</button></a>
          </header>
        </div>
      </div>

  );
}

export default Home;
