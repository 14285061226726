import '../App.css';
import React, { useEffect, useState } from "react";
import UxIcon from '../Assets/ux-icon.png';
import SeoIcon from '../Assets/seo-icon.png';
import BrandIcon from '../Assets/brand-icon.png';
import MobileIcon from '../Assets/mobile-icon.png';

function Services() {
  const page = useState('https://www.085digital.com');

  useEffect(() => {

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const animatedTop = entry.target.querySelector('.animated-top');
        const animatedBottom = entry.target.querySelector('.animated-bottom');
        
        if (entry.isIntersecting) {
          animatedTop.classList.add('fixed-top');
          animatedBottom.classList.add('fixed-bottom');
        return; // if we added the class, exit the function
        }
    
        // We're not intersecting, so remove the class!
        animatedTop.classList.remove('fixed-top');
        animatedBottom.classList.remove('fixed-bottom');
      });
    });
    
    observer.observe(document.querySelector('#Services')); 

  }, [page])

  return (
    <div className="container" id='Services'>
        <div className='animated-top'>
          <h2>Services</h2>
        </div>
        <div className='animated-bottom cards'>
            <div className='work-card'>
                <h3>Landing Pages</h3>
                <img src={UxIcon} alt='User Experience'/>
                <p>
                    We create the website your business needs
                </p>
                <ul>
                    <li>Landing pages</li>
                    <li>Website Strategy</li>
                    <li>Website Hosting</li>
                    <li>Support after launch</li>
                    <li>Fully customized website</li>
                    <li>Squarespace and WIX websites</li>
                    <li>Custom coding available</li>
                </ul>
            </div>
            <div className='work-card'>
                <h3>SEO</h3>
                <img src={SeoIcon} alt='SEO icons'/>
                <p>
                    We help your business to be seen online
                </p>
                <ul>
                    <li>Improvement of brand visibility</li>
                    <li>Keyword research</li>
                    <li>Website Audit</li>
                    <li>Basic link building</li>
                    <li>Competitor analysis</li>
                    <li>Monthly reports</li>
                    <li>Google Analytics setup</li>
                </ul>
            </div>
            <div className='work-card'>
                <h3>Branding</h3>
                <img src={BrandIcon} alt='Branding'/>
                <p>
                    We help your business to find it's voice
                </p>
                <ul>
                    <li>Logo design</li>
                    <li>Brand color palette</li>
                    <li>Target users</li>
                    <li>Competitor analysis</li>
                    <li>Newsletter setup</li>
                    <li>Brand guidelines</li>
                    <li>Email signatures</li>
                </ul>
            </div>
            <div className='work-card'>
                <h3>Mobile Apps</h3>
                <img src={MobileIcon} alt='Mobile Apps'/>
                <p>
                    We create solutions for your mobile app
                </p>
                <ul>
                    <li>UX research</li>
                    <li>User personas</li>
                    <li>Wireframes and high fidelity prototypes</li>
                    <li>Style guide</li>
                    <li>Web and iOS development</li>
                    <li>Support after launch</li>
                </ul>
            </div>
        </div>
    </div>
  );
}

export default Services;
