import './App.css';
import Home from './components/Home.js';
import Services from './components/Services.js';
import OurProcess from './components/OurProcess.js';
import About from './components/About.js';
import Works from './components/Works.js';
import Contact from './components/Contact.js';
import FAQ from './components/FAQ.js';
import Footer from './components/Footer.js';
import Navbar from './components/Navbar.js';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Home/>
      <Services/>
      <OurProcess/>
      <About/>
      <Works/>
      <Contact/>
      <FAQ/>
      <Footer/>
    </div>
  );
}

export default App;
