import '../App.css';
import React, { useEffect, useState } from "react";
import { ContactForm } from './utils/ContactForm';

function Contact() {
  const page = useState('https://www.085digital.com');

  useEffect(() => {

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const animatedTop = entry.target.querySelector('.animated-top');
        const animatedBottom = entry.target.querySelector('.animated-bottom');
        
        if (entry.isIntersecting) {
          animatedTop.classList.add('fixed-top');
          animatedBottom.classList.add('fixed-bottom');
        return; // if we added the class, exit the function
        }
    
        // We're not intersecting, so remove the class!
        animatedTop.classList.remove('fixed-top');
        animatedBottom.classList.remove('fixed-bottom');
      });
    });
    
    observer.observe(document.querySelector('#Contact')); 

  }, [page])
  
  return (
    <div className="container" id='Contact'>
        <div className='animated-top'>
          <h2>Got a project in mind?</h2>
        </div>
        <div className='animated-bottom'>
            <ContactForm />
        </div>
    </div>
  );
}

export default Contact;
